body {
  background-color: #fafafa;
}

#public_frame {
  background-color: #fff;
  margin: auto;
  margin-top: 60px;
  width: 400px;
  max-width: 100%;
  padding: 20px 20px 10px 20px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
#public_frame .form-control {
  height: 44px;
  font-size: 16px;
}
#public_frame #otp {
  font-size: 1.6rem;
  font-weight: bold;
  text-align: center;
}
#public_frame .form-submit .btn {
  width: 100%;
  height: 44px;
}